import './CryptoPurchaseCard.css';
import React, { useEffect, useState } from 'react';
import {Web3Button } from '@web3modal/react';
import { useAccount } from 'wagmi';
import { fetchBalance, sendTransaction } from '@wagmi/core';
import { parseEther } from 'viem';

// SVG Imports
import etherIcon from './assets/images/svg-icons/eth.svg';
import usdtIcon from './assets/images/svg-icons/usdt.svg';
import bnbIcon from './assets/images/svg-icons/bnb.svg';

const buyText = "BUY"

// USDTether (ERC20)
const usdtContractAddress = '0xdac17f958d2ee523a2206206994597c13d831ec7';

// Binance Coin BNB
const bnbContractAddress = '0xB8c77482e45F1F44dE1745F52C74426C631bDD52';

const CryptoPurchaseCard = ({toAddress }) => {
  const [selectedButton, setSelectedButton] = useState('');

  const handleTokenSelect = (token) => {
    setSelectedToken(token);
    setSelectedButton(token); // Update selectedButton state when a button is clicked
  };

  const { address, isConnected } = useAccount();
  const [balance, setBalance] = useState(null);
  const [transactionAmount, setTransactionAmount] = useState('');
  const [selectedToken, setSelectedToken] = useState('ETH');

  useEffect(() => {
    const fetchAccountBalance = async () => {
      if (isConnected) {
        const balance = await fetchBalance({ address });
        setBalance(balance);
      } else {
        setBalance(0);
      }
    };

    fetchAccountBalance();
  }, [address, isConnected]);

  const handleTransactionAmountChange = (event) => {
    setTransactionAmount(event.target.value);
  };

  const handleSendTransaction = async () => {
    if (!transactionAmount) {
      alert('Please enter a transaction amount');
      return;
    }

    try {
      if (selectedToken === 'ETH') {
        // Sending Ether transaction
        await sendTransaction({
          to: toAddress,
          value: parseEther(transactionAmount),
        });
      }
      else if (selectedToken === 'USDT') {
        // Sending USDT transaction
        await sendTransaction({
          to: toAddress,
          // Divide the amount by 10^6 to adjust for the 6 decimal places
          value: parseEther((transactionAmount / 10 ** 6)),
          tokenAddress: usdtContractAddress,
        });
      }
      else if (selectedToken === "BNB") {
          // Sending Ether transaction
          await sendTransaction({
            to: toAddress,
            value: parseEther(transactionAmount),
            tokenAddress: bnbContractAddress,
          });
      }
      alert('Transaction sent successfully!');
    } 
    catch (error) {
      alert('Error sending transaction: ' + error.message);
    }
  }

  return (
    <div>
      <div className="modal">
        <form className="form">
          <div className="payment--options">
            <button
              name="ether"
              type="button"
              className={selectedButton === 'ETH' ? 'clicked' : ''}
              onClick={() => handleTokenSelect('ETH')}
            >
              <img className="logo" src={etherIcon} alt="" />
              <span className='logo-text'>Ethereum</span>
            </button>
            <button
              disabled={true}
              name="tether"
              type="button"
              title='Coming soon...'
              className={selectedButton === 'USDT' ? 'clicked' : ''}
              onClick={() => handleTokenSelect('USDT')}
            >
              <img className="logo" src={usdtIcon} alt="" />
              <span className='logo-text'>Tether</span>
            </button>
            <button 
              disabled={true}
              name="bnb"
              type="button"
              className={selectedButton === 'BNB' ? 'clicked' : ''}
              title='Coming soon...'
              onClick={() => handleTokenSelect('BNB')}
              >
                <img className="logo" src={bnbIcon} alt="" />
                <span className='logo-text'>Binance</span>
            </button>
          </div>
          <div className="separator">
            <hr className="line" />
            <p>Payment Methods</p>
            <hr className="line" />
          </div>
          <div className="info--form">
            <div className="input_container">
              <label htmlFor="amount_field" className="input_label">
                Transaction Amount
              </label>
              <div className="input-wrapper">
                <input
                  id="amount_field"
                  className="input_field"
                  type='number'
                  name="input-name"
                  // title="Amount"
                  placeholder="0"
                  onInput={ e => handleTransactionAmountChange(e) }
                />
                <img
                  className="input_icon"
                  src={
                    selectedToken === 'USDT' ? usdtIcon
                    : selectedToken === 'ETH' ? etherIcon 
                    : selectedToken === 'BNB' ? bnbIcon : etherIcon
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="input_container">
              {/* <hr className="line" /> */}
              <div className="connect-button" >
                <Web3Button />
              <label className="wallet-balance-label">
                BAL: {balance && parseFloat(balance.formatted).toFixed(4)} {balance && balance.symbol}
              </label>
              </div>
            <hr className="line" />
              <button className="buy--btn" onClick={handleSendTransaction}>{buyText}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CryptoPurchaseCard;
