import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal} from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { arbitrum, mainnet, polygon, avalanche } from 'wagmi/chains';
import CryptoPurchaseCard from './CryptoPurchaseCard';

const chains = [arbitrum, mainnet, polygon, avalanche];
const projectId = 'fe9535a042649dbc203095481007aa6f';

// The address users will send their money to
const to = '0x8Ee89aD8299C9FF3c5F824b992249F866c9771C4';

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
      <CryptoPurchaseCard
        toAddress={to}
      />
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient}
        themeVariables={{
          '--w3m-font-family': 'Roboto, sans-serif',
          '--w3m-accent-color': '#40af21',
          '--w3m-background-color': '#272a2a',
          '--w3m-background-border-radius': '20px', 
          // '--w3m-button-border-radius': '20px'
        }}
      />
    </>
  );
}

export default App;